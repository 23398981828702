import React, { PureComponent } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import AppWithRouter from "AppWithRouter";
import "resources/css/index.scss";

const browserHistory = createBrowserHistory();

export default class App extends PureComponent {
  render() {
    return (
      <Router history={browserHistory}>
        <AppWithRouter />
      </Router>
    );
  }
}
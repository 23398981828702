import { FETCH_CACHING_DATA_SUCCESS } from "redux/actions/actionTypes";

import InitialState from "./InitialState";
const STATE_SLICE_NAME = "cachedData"; // MUST match INITIAL STate key

export function cachingReducer(state = InitialState[STATE_SLICE_NAME], action) {
  switch (action.type) {
    case FETCH_CACHING_DATA_SUCCESS: {
      const { apiIdentifier, data, userGroup } = action;
      const currentCopy = Object.assign({}, state[apiIdentifier] || {});
      currentCopy[userGroup] = data;
      const newState = { ...state, [apiIdentifier]: currentCopy };
      return newState;
    }

    default:
      return state;
  }
}

export const selectors = {
  getResult: appState => apiIdentifier =>
    appState[STATE_SLICE_NAME][apiIdentifier] || {},
  getAll: appState => appState[STATE_SLICE_NAME]
};

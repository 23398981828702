/**
 * constant denoting Begin an API Call.
 */
export const BEGIN_API_CALL = "BEGIN_API_CALL";
/**
 * constant denoting End an API Call.
 */
export const END_API_CALL = "END_API_CALL";
export const CHECK_USER_AUTHENTICATION = "CHECK_USER_AUTHENTICATION";
export const GET_VIDEO_NAME = "GET_VIDEO_NAME";
export const SET_USER_TOKENS = "SET_USER_TOKENS";
export const UPDATE_USER_HRID = "UPDATE_USER_HRID";
export const UPDATE_OVERLAY_STATE = "UPDATE_OVERLAY_STATE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const GET_DRAFT_REPORT = "GET_DRAFT_REPORT";
export const UPDATE_REPORT_LISTING_DATA = "UPDATE_REPORT_LISTING_DATA";
export const UPDATE_REPORT_FAV_LISTING = "UPDATE_REPORT_FAV_LISTING";
export const UPDATE_REPORT_FAV_LISTING_SUCCESS =
  "UPDATE_REPORT_FAV_LISTING_SUCCESS";
export const UPDATE_REPORT_FAV_LISTING_FAILURE =
  "UPDATE_REPORT_FAV_LISTING_FAILURE";
export const UPDATE_PREFERRED_VIEW = "UPDATE_PREFERRED_VIEW";
export const UPDATE_REPORT_FAV_LISTING_BY_CATEGORY =
  "UPDATE_REPORT_FAV_LISTING_BY_CATEGORY";
export const UPDATE_SEARCHED_REPORTS_DATA = "UPDATE_SEARCHED_REPORTS_DATA";
export const UPDATE_REPORT_LISTING_DATA_BY_CATEGORY =
  "UPDATE_REPORT_LISTING_DATA_BY_CATEGORY";
export const UPDATE_NO_FAV_DATA = "UPDATE_NO_FAV_DATA";
export const UPDATE_NO_REPORT_DATA = "UPDATE_NO_REPORT_DATA";
export const UPDATE_NO_CONTACT_DATA = "UPDATE_NO_CONTACT_DATA";
export const UPDATE_MASTER_SEARCHED_DATA = "UPDATE_MASTER_SEARCHED_DATA";
export const UPDATE_RECENT_SEARCHED = "UPDATE_RECENT_SEARCHED";
export const UPDATE_SHOW_MASTER_SEARCH_DATA_LIST =
  "UPDATE_SHOW_MASTER_SEARCH_DATA_LIST";
export const UPDATE_LATEST_NEW_REPORT = "UPDATE_LATEST_NEW_REPORT";
export const GET_UPLOAD_PERCENTAGE = "GET_UPLOAD_PERCENTAGE";
export const UPDATE_REPORTS_COUNT = "UPDATE_REPORTS_COUNT";
export const FETCH_REPORT = "FETCH_REPORT";
export const UPDATE_PAGE_OVERLAY_STATE = "UPDATE_PAGE_OVERLAY_STATE";
export const FETCH_REPORT_DETAIL = "FETCH_REPORT_DETAIL";
/**
 * constant denoting a SUCCESS of 'a' Seed Data fetch api operation
 */
export const FILTER_DATA_FETCH_SUCCESS = "FILTER_DATA_FETCH_SUCCESS";

/**
 * constant denoting a FAILURE of 'a' Seed Data fetch api operation
 */
export const FILTER_DATA_FETCH_FAILURE = "FILTER_DATA_FETCH_FAILURE";

/**
 * this key is used to Save the App notification detail
 */
export const SAVE_APP_NOTIFICATION = "SAVE_APP_NOTIFICATION";
/**
 * this key is used to delete the specific  app notification detail
 */
export const DELETE_APP_NOTIFICATION = "DELETE_APP_NOTIFICATION";
/**
 * constant denoting SUCCESS for Case Search api operation
 */
export const CASE_SEARCH_SUCCESS = "CASE_SEARCH_SUCCESS";


/**
 * constant denoting SUCCESS for Best Bet Summary Search api operation
 */
export const BEST_BET_SUMMARY_SUCCESS = 'BEST_BET_SUMMARY_SEARCH_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Summary Search api operation
 */
export const PROFILE_COMPLETION_SUMMARY_SUCCESS = 'PROFILE_COMPLETION_SUMMARY_SEARCH_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Summary Search api operation
*/
export const PROFILE_COMPLETION_PA_DATA_SUCCESS = 'PROFILE_COMPLETION_PA_DATA_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Summary Search api operation
*/
export const PROFILE_COMPLETION_EXPERTISE_DATA_SUCCESS = 'PROFILE_COMPLETION_EXPERTISE_DATA_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Skills Search api operation
*/
export const PROFILE_COMPLETION_SKILLS_DATA_SUCCESS = 'PROFILE_COMPLETION_SKILLS_DATA_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Sections Search api operation
*/
export const PROFILE_COMPLETION_SECTIONS_DATA_SUCCESS = 'PROFILE_COMPLETION_SECTIONS_DATA_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Region Search api operation
*/
export const PROFILE_COMPLETION_REGION_DATA_SUCCESS = 'PROFILE_COMPLETION_REGION_DATA_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Sections Search api operation
*/
export const PROFILE_COMPLETION_WORKER_TYPE_SUCCESS = 'PROFILE_COMPLETION_WORKER_TYPE_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Sections Search api operation
*/
export const PROFILE_COMPLETION_WORKER_STATUS_SUCCESS = 'PROFILE_COMPLETION_WORKER_STATUS_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion BIO Downloads  api operation
*/
export const PROFILE_COMPLETION_BIO_DOWNLOAD_SUCCESS = 'PROFILE_COMPLETION_REGION_BIO_DOWNLOAD_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Resume Downloads  api operation
*/
export const PROFILE_COMPLETION_RESUME_DOWNLOAD_SUCCESS = 'PROFILE_COMPLETION_REGION_RESUME_DOWNLOAD_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Completion trend  api operation
*/
export const PROFILE_COMPLETION_COMPLETION_TREND_SUCCESS = 'PROFILE_COMPLETION_COMPLETION_TREND_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Section Completion api operation
*/
export const PROFILE_COMPLETION_SECTION_COMPLETION_SUCCESS = 'PROFILE_COMPLETION_SECTION_COMPLETION_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Material Downloads  api operation
*/
export const PROFILE_COMPLETION_MATERIAL_DOWNLOADS_SUCCESS = 'PROFILE_COMPLETION_MATERIAL_DOWNLOADS_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Section Completion api operation
*/
export const PROFILE_COMPLETION_MATERIAL_CONTRIBUTED_SUCCESS = 'PROFILE_COMPLETION_MATERIAL_CONTRIBUTED_SUCCESS';

/**
 * constant denoting SUCCESS for Profile Completion Section Completion api operation
*/
export const PROFILE_COMPLETION_CASES_SUCCESS = 'PROFILE_COMPLETION_CASES_SUCCESS';

export const PROFILE_COMPLETION_POPULAR_AUTHOR_SUCCESS = 'PROFILE_COMPLETION_POPULAR_AUTHOR_SUCCESS';


/**
 * constant denoting SUCCESS for Case Search api operation
 */
export const CASE_SEARCH_CONTENT_USAGE_SUCCESS = "CASE_SEARCH_CONTENT_USAGE_SUCCESS";

/**
 *  constant denoting FAILURE for Case Search api operation
 */
export const CASE_SEARCH_FAILURE = "CASE_SEARCH_FAILURE";
/**
 * Constant denoting the request to save the Selected filters in the state
 */
export const SAVE_APPLIED_FILTERS = "SAVE_APPLIED_FILTERS";


/**
 * Constant denoting the request to save the Selected GTM Hub filters in the state
 */
export const SAVE_APPLIED_GTM_FILTERS = "SAVE_APPLIED_GTM_FILTERS";

/**
 * Constant denoting the request to save the download report history in the state
 */
export const GET_DOWNLOAD_HISTORY = "GET_DOWNLOAD_HISTORY";

/**
 * Constant denoting the request to save the download report history in the state
 */
export const SHOW_DOWNLOAD_HISTORY = "SHOW_DOWNLOAD_HISTORY";

export const GET_ATTACHMENTS_DATA = "GET_ATTACHMENTS_DATA";

export const GET_DOWNLOADS_DATA = "GET_DOWNLOADS_DATA";

export const GET_AVAILABILITY_DATA = "GET_AVAILABILITY_DATA";

export const GET_KNOWLEDGE_PAGE_DATA = "GET_KNOWLEDGE_PAGE_DATA";

export const FETCH_SERVICES_LIST_SUCCESS = "FETCH_SERVICES_LIST_SUCCESS";

export const FETCH_SERVICE_DESCRIPTION_SUCCESS =
  "FETCH_SERVICE_DESCRIPTION_SUCCESS";

export const FETCH_KEY_UPDATES_SUCCCESS = "FETCH_KEY_UPDATES_SUCCCESS";

export const FETCH_METRICS_CONTENT_SUCCESS = "FETCH_METRICS_CONTENT_SUCCESS";

export const SAVE_PEOPLE_DETAILS = "SAVE_PEOPLE_DETAILS";

export const FETCH_CACHING_DATA_SUCCESS = "FETCH_CACHING_DATA_SUCCESS";

export const FETCH_TIME_LABEL_LIST_SUCCESS = "FETCH_TIME_LABEL_LIST_SUCCESS";

export const FETCH_IMP_LINKS_LIST_SUCCESS = "FETCH_IMP_LINKS_LIST_SUCCESS";

export const FETCH_REVIEW_COMMENTS_SUCCESS = "FETCH_REVIEW_COMMENTS_SUCCESS";
/**
 * Constant denoting the request to save the analytics data in the state
 */
export const ADOBE_ANALYTICS_TOKEN_SUCCESS = "ADOBE_ANALYTICS_TOKEN_SUCCESS";
export const ADOBE_ANALYTICS_TOKEN_FAILURE = "ADOBE_ANALYTICS_TOKEN_FAILURE";

export const ADOBE_ANALYTICS_DATA_SUCCESS = "ADOBE_ANALYTICS_DATA_SUCCESS";
export const ADOBE_ANALYTICS_DATA_FAILURE = "ADOBE_ANALYTICS_DATA_FAILURE";

export const ADOBE_APPLIED_FILTER = "ADOBE_APPLIED_FILTER";

export const ADOBE_APPLIED_GTM_FILTER = 'ADOBE_APPLIED_GTM_FILTER';

export const FETCH_DOWNLOAD_DETAILS = "FETCH_DOWNLOAD_DETAILS";
export const FETCH_KP_DOWNLOAD_DETAILS = "FETCH_KP_DOWNLOAD_DETAILS";

export const FETCH_PEOPLE_IMAGES_IN_PROGRESS = "FETCH_PEOPLE_IMAGES_IN_PROGRESS";

export const FETCH_PEOPLE_IMAGES_SUCCESS = "FETCH_PEOPLE_IMAGES_SUCCESS";
export const FETCH_ACTION_ITEMS_SUCCESS = "FETCH_ACTION_ITEMS_SUCCESS";

export const GET_GTM_CONTENT_DETAILS = "GET_GTM_CONTENT_DETAILS";

export const CHECK_IE_BROWSER = 'CHECK_IE_BROWSER';
import {
  FETCH_PEOPLE_IMAGES_SUCCESS,
  FETCH_PEOPLE_IMAGES_IN_PROGRESS
} from "redux/actions/actionTypes";

import InitialState from "./InitialState";
const STATE_SLICE_NAME = "peopleImage"; // MUST match INITIAL STate key

export function PeopleImagesReducer(
  state = InitialState[STATE_SLICE_NAME],
  action
) {
  switch (action.type) {
    case FETCH_PEOPLE_IMAGES_SUCCESS: {
      const { hrId, imageBytes } = action;
      return {
        ...state,
        [hrId]: { img: imageBytes, activeRequest: 0 }
      };
    }
    case FETCH_PEOPLE_IMAGES_IN_PROGRESS: {
      const { hrId } = action;
      const { img } = { ...state[hrId] };
      return {
        ...state,
        [hrId]: { img, activeRequest: 1 }
      };
    }
    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  getImageByHrId: appState => hrId =>
    appState[STATE_SLICE_NAME] && appState[STATE_SLICE_NAME][hrId] && appState[STATE_SLICE_NAME][hrId].img || "",
};

import React from "react";
import "./Spinner.scss";

export default function Spinner(props) {
  return (
    <div className="lds-ellipsis" style={props.styleProp}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

import {
  CASE_SEARCH_FAILURE,
  CASE_SEARCH_SUCCESS,
  SAVE_APPLIED_FILTERS,
  GET_ATTACHMENTS_DATA,
  GET_DOWNLOADS_DATA,
  GET_AVAILABILITY_DATA,
  GET_KNOWLEDGE_PAGE_DATA,
  GET_GTM_CONTENT_DETAILS,
  CASE_SEARCH_CONTENT_USAGE_SUCCESS,
  SAVE_APPLIED_GTM_FILTERS,
  BEST_BET_SUMMARY_SUCCESS,
  PROFILE_COMPLETION_SUMMARY_SUCCESS,
  PROFILE_COMPLETION_PA_DATA_SUCCESS,
  PROFILE_COMPLETION_EXPERTISE_DATA_SUCCESS,
  PROFILE_COMPLETION_SKILLS_DATA_SUCCESS,
  PROFILE_COMPLETION_SECTIONS_DATA_SUCCESS,
  PROFILE_COMPLETION_REGION_DATA_SUCCESS,
  PROFILE_COMPLETION_BIO_DOWNLOAD_SUCCESS,
  PROFILE_COMPLETION_RESUME_DOWNLOAD_SUCCESS,
  PROFILE_COMPLETION_COMPLETION_TREND_SUCCESS,
  PROFILE_COMPLETION_SECTION_COMPLETION_SUCCESS,
  PROFILE_COMPLETION_MATERIAL_DOWNLOADS_SUCCESS,
  PROFILE_COMPLETION_MATERIAL_CONTRIBUTED_SUCCESS,
  PROFILE_COMPLETION_CASES_SUCCESS,
  PROFILE_COMPLETION_POPULAR_AUTHOR_SUCCESS,
  PROFILE_COMPLETION_WORKER_TYPE_SUCCESS,
  PROFILE_COMPLETION_WORKER_STATUS_SUCCESS,
} from "redux/actions/actionTypes";

import InitialState from "./InitialState";
import { STATE_KEY } from "./constants";
const STATE_SLICE_NAME = "appliedFilter"; // MUST match INITIAL STate key

/**
 * This reducer will return the state-slice for case search.
 * When the action.type is :
 * -  CASE_SEARCH_FAILURE the action will have a property 'caseSearchError'
 * -  CASE_SEARCH_SUCCESS the action will have a property 'caseSearchResult'
 * -  SAVE_APPLIED_FILTERS the action will have a property 'filters'
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function appliedFilterReducer(
  state = InitialState[STATE_SLICE_NAME],
  action
) {
  switch (action.type) {
    //invoked when request to save the applied filters is made
    case SAVE_APPLIED_FILTERS:
      //set the filters applied for this API call
      return {
        ...state,
        filters: action.filters
      };

    case SAVE_APPLIED_GTM_FILTERS:
      return {
        ...state,
        gtmFilters: { ...state.gtmFilters, ...action.filters }
      };

    //invoked when Case Search api call is a Success
    case CASE_SEARCH_SUCCESS:
      return {
        ...state,
        results: action.caseSearchResult,
        error: ""
      };

    //invoked when Case Search api call is a Success
    case BEST_BET_SUMMARY_SUCCESS:
      return {
        ...state,
        resultsBestBetSummary: action.caseSearchResult,
        error: ""
      };

    //invoked when Profile completion Summary Search api call is a Success
    case PROFILE_COMPLETION_SUMMARY_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.summary]: action.caseSearchResult,
        error: ""
      };

    //invoked when Profile completion Summary Search api call is a Success
    case PROFILE_COMPLETION_REGION_DATA_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.region]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_WORKER_TYPE_SUCCESS: return {
      ...state,
      [STATE_KEY.PROFILE_COMPLETION.workerType]: action.caseSearchResult,
      error: ""
    };

    case PROFILE_COMPLETION_WORKER_STATUS_SUCCESS: return {
      ...state,
      [STATE_KEY.PROFILE_COMPLETION.workerStatus]: action.caseSearchResult,
      error: ""
    };

    case PROFILE_COMPLETION_PA_DATA_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.practiceArea]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_SKILLS_DATA_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.skills]: action.caseSearchResult,
        error: ""
      };
    case PROFILE_COMPLETION_SECTIONS_DATA_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.sections]: action.caseSearchResult,
        error: ""
      };
    case PROFILE_COMPLETION_EXPERTISE_DATA_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.expertise]: action.caseSearchResult,
        error: ""
      };

    //invoked when Profile completion Bio Download api call is a Success
    case PROFILE_COMPLETION_BIO_DOWNLOAD_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.bioDownloads]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_RESUME_DOWNLOAD_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.resumeDownloads]: action.caseSearchResult,
        error: ""
      };

    //invoked when Profile completion material Download/contributed api call is a Success
    case PROFILE_COMPLETION_MATERIAL_DOWNLOADS_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.materialDownloads]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_MATERIAL_CONTRIBUTED_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.materialContributed]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_CASES_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.cases]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_POPULAR_AUTHOR_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.popularAuthor]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_COMPLETION_TREND_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.completionTrend]: action.caseSearchResult,
        error: ""
      };

    case PROFILE_COMPLETION_SECTION_COMPLETION_SUCCESS:
      return {
        ...state,
        [STATE_KEY.PROFILE_COMPLETION.sectionCompletion]: action.caseSearchResult,
        error: ""
      };

    case CASE_SEARCH_CONTENT_USAGE_SUCCESS:
      return {
        ...state,
        contentUsageResults: action.caseSearchResult,
        error: ""
      };

    //invoked when case search api call is failure
    case CASE_SEARCH_FAILURE:
      //assign the error to store
      return { ...state, error: action.caseSearchError, results: {} };

    case GET_DOWNLOADS_DATA:
      //assign data to store
      return {
        ...state,
        downloadsData: action.downloadsDataResults
      };

    case GET_ATTACHMENTS_DATA:
      //assign data to store
      return {
        ...state,
        attachmentData: action.attachmentDataResults
      };

    case GET_AVAILABILITY_DATA:
      //assign data to store
      return {
        ...state,
        availabilityData: action.availabilityDataResults
      };

    case GET_KNOWLEDGE_PAGE_DATA:
      //assign data to store
      return {
        ...state,
        knowledgePageData: action.knowledgePageDataResults
      };
    case GET_GTM_CONTENT_DETAILS:
      // assign data to store
      return {
        ...state,
        gtmContentData: action.gtmContentDataResults
      };

    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  getAppliedFilters: appState => appState[STATE_SLICE_NAME].filters,
  getResults: appState => appState[STATE_SLICE_NAME].results,
  getContentUsageResults: appState => appState[STATE_SLICE_NAME].contentUsageResults,
  getError: appState => appState[STATE_SLICE_NAME].error,
  getDownloadsData: appState => appState[STATE_SLICE_NAME].downloadsData,
  getAvailabilityData: appState => appState[STATE_SLICE_NAME].availabilityData,
  getKnowledgePageData: appState => appState[STATE_SLICE_NAME].knowledgePageData,
  getAttachmentData: appState => appState[STATE_SLICE_NAME].attachmentData,
  gtmContentData: appState => appState[STATE_SLICE_NAME].gtmContentData,
  getProfileCompletionResults: (appState, stateKey) => appState[STATE_SLICE_NAME][stateKey],
};

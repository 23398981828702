import InitialState from "./InitialState";
import { FETCH_METRICS_CONTENT_SUCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "metrices";

export const MetricsReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_METRICS_CONTENT_SUCCESS: {
      const { quality, leverage, risk, cost, ux, projectPlan } = action;
      return { ...state, quality, leverage, risk, cost, ux, projectPlan };
    }
    default:
      return state;
  }
};

export const selectors = {
  getMetricsContent: appState => appState[STATE_SLICE_NAME] || {}
};

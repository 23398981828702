/**
 * The INITIAL State of the Application
 */
const InitialState = {
  /**
   * this key is responsible for storing :
   * -  the Case Search results in 'NORMALIZED' form.{"caseID":{caseObject}, ... }
   * -  error during api execution. (After every success, this field is cleared)
   * -  Filters Applied to make this call.
   */
  appliedFilter: {
    /**
     * the Case Search results in 'NORMALIZED' form.{"caseID":{caseObject}, ... }
     */
    results: {},
    /**
     * error during api execution. (After every success, this field should be cleared)
     */
    error: "",
    /**
     * ToDo: Filters Applied to make this api call
     */
    filters: {},
    /**
     * ToDo:Fetch Report Availability Data
     */
    availabilityData: {},
    /**
     * ToDo:Fetch Report Download Data
     */
    downloadsData: {},
    /**
     * ToDo:Fetch Report Attachment Data
     */
    attachmentData: {},

    contentUsageResults: {},
    gtmFilters: {}
  },
  /**
   * this key is responsible for saving the Seed Data for Filters
   * - In most cases data is stored as array of {value:'',label:''}
   */
  filterData: {
    topicTree: { results: {}, error: "" },
    practiceAreas: { results: {}, error: "" },
    otherTopicTree: { results: {}, error: "" },
    interactiveReport: {}
  },

  /**
   *  this key is responsible for storing the API calls in progress at the moment.The data is saved as
   * {"apiIdentifier" : <countOfActiveCalls>, ... }
   */
  apiCallsInProgress: {},

  /**
   * this key is responsible for storing the Case Details for the specific Case Number the user requested for.
   */

  /**
   * this key is responsible for storing the ShortURL along with the input used for requesting the URL
   */
  shortUrl: {
    /**
     *  Filter String used to generate the URL
     */
    input: "",
    /**
     *generated Short URL
     */
    result: "",
    /**
     *error returned
     */
    error: ""
  },
  loggedInUserDetails: { userDetails: {}, error: "" },
  /**
   * this key is responsible for storing the people information with HrId as a Key
   */
  peopleByHrId: {},
  /**
   * this key is responsible for storing the Practice Areas Assigned to a project
   */
  assignedPracticeAreas: {},
  /**
   * key for Storing Application Notifications
   */
  appNotifications: {
    /**
     * object with key as notificationId
     *
     *  example: notificationId:{ message,timestamp, notificationType,group}
     */
  },
  seedData: { servicesList: {}, timeLabelList: {} },
  serviceDetails: {},
  keyUpdates: {},
  metrices: {},
  cachedData: {},
  impLinks: {},
  reviewComments: [],
  actionItems: [],
  /**
   * Key to store analytics data
   */
  adobeAnalytics: {
    token: undefined,
    error: undefined,
    analyticsData: {},
    filterObject: {}
  },

  interactiveInfo: {
    /**
     * ToDo:Fetch Download Details
     */
    downloadDetails: {},
    /**
     * ToDo:Fetch KP Download Details
     */
    kpDownloadDetails: {},
    /**
     * ToDo:Fetch Report Download History
     */
    downloads: {}
  },
  peopleImage: {}
};

export default InitialState;

import InitialState from "./InitialState";
import { FETCH_REVIEW_COMMENTS_SUCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "reviewComments";

export const ReviewCommentReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_REVIEW_COMMENTS_SUCCESS: {
      const { data } = action;
      return data;
    }
    default:
      return state;
  }
};

export const selectors = {
  getReviewComments: appState => appState[STATE_SLICE_NAME] || {}
};

import InitialState from "./InitialState";
import { FETCH_SERVICE_DESCRIPTION_SUCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "serviceDetails";

export const ServiceDashboardReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_SERVICE_DESCRIPTION_SUCCESS: {
      const { serviceName, ownerName, description } = action;
      return { ...state, serviceName, ownerName, description };
    }
    default:
      return state;
  }
};

export const selectors = {
  getServiceDetails: () => [] //appState[STATE_SLICE_NAME] || {}
};

import { combineReducers } from "redux";
import {
  selfServiceReducer,
  selectors as selfServiceSelectors
} from "./selfServiceReducer";
import {
  filterSectionReducer,
  selectors as filterSectionSelectors
} from "./filterSectionReducer";
import {
  apiCallStatusReducer,
  selectors as apiCallStatusSelectors
} from "./apiCallStatusReducer";

import {
  SeedDataReducer,
  selectors as SeedDataSelector
} from "./seedDataReducer";
import {
  ServiceDashboardReducer,
  selectors as ServiceDashboardSelector
} from "./serviceDashboardReducer";

import {
  appliedFilterReducer,
  selectors as appliedFilterSelectors
} from "./appliedFilterReducer";
import {
  adobeAnalyticsReducer,
  selectors as adobeAnalyticsSelector
} from "./adobeAnalyticsReducer";

import {
  KeyUpdatesReducer,
  selectors as KeyUpdatesSelector
} from "./keyUpdatesReducer";

import {
  MetricsReducer,
  selectors as MetricsSelector
} from "./metricsContentReducer";

import { cachingReducer, selectors as CachingSelector } from "./cachingReducer";

import { peopleReducer, selectors as PeopleSelector } from "./peopleReducer";

import {
  ImpLinksReducer,
  selectors as ImpLinksSelector
} from "./impLinksReducer";

import {
  ReviewCommentReducer,
  selectors as ReviewCommentSelector
} from "./reviewCommentReducer";

import {
  ActionItemsReducer,
  selectors as ActionItemsSelector
} from "./actionItemsReducer";

import {
  InteractiveReducer,
  selectors as InteractiveSelector
} from "./interactiveReducer";

import {
  PeopleImagesReducer,
  selectors as PeopleImageSelector
} from "./peopleImagesReducer";
import CONFIG_REPORT from "config/config-report";
import checkBrowserReducer from "./checkBrowserReducer";

export const reducers = combineReducers({
  selfService: selfServiceReducer,
  filterData: filterSectionReducer,
  checkBrowserReducer: checkBrowserReducer,
  apiCallsInProgress: apiCallStatusReducer,
  appliedFilter: appliedFilterReducer,
  seedData: SeedDataReducer,
  peopleByHrId: peopleReducer,
  cachedData: cachingReducer,
  impLinks: ImpLinksReducer,
  [CONFIG_REPORT.IT_DASHBOARD_CONSTANTS.STATE_KEYS
    .SERVICE_INFO]: ServiceDashboardReducer,
  [CONFIG_REPORT.IT_DASHBOARD_CONSTANTS.STATE_KEYS
    .KEY_UPDATES]: KeyUpdatesReducer,
  [CONFIG_REPORT.IT_DASHBOARD_CONSTANTS.STATE_KEYS.METRICS]: MetricsReducer,
  reviewComments: ReviewCommentReducer,
  adobeAnalytics: adobeAnalyticsReducer,
  actionItems: ActionItemsReducer,
  interactiveInfo: InteractiveReducer,
  peopleImage: PeopleImagesReducer,
});

export const selectors = {
  filterSectionSelectors,
  apiCallStatusSelectors,
  appliedFilterSelectors,
  SeedDataSelector,
  ServiceDashboardSelector,
  KeyUpdatesSelector,
  MetricsSelector,
  PeopleSelector,
  CachingSelector,
  ImpLinksSelector,
  ReviewCommentSelector,
  adobeAnalyticsSelector,
  ActionItemsSelector,
  selfServiceSelectors,
  InteractiveSelector,
  PeopleImageSelector
};

import InitialState from "./InitialState";
import { FETCH_KEY_UPDATES_SUCCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "keyUpdates";

export const KeyUpdatesReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_KEY_UPDATES_SUCCCESS: {
      const { achievements, upcomingActivities, issuesRisks, other } = action;
      return { ...state, achievements, upcomingActivities, issuesRisks, other };
    }
    default:
      return state;
  }
};

export const selectors = {
  getKeyUpdates: appState => appState[STATE_SLICE_NAME] || {}
};

import {
    GET_DOWNLOAD_HISTORY,
    FETCH_DOWNLOAD_DETAILS,
    FETCH_KP_DOWNLOAD_DETAILS
} from "redux/actions/actionTypes";

import InitialState from "./InitialState";
const STATE_SLICE_NAME = "interactiveInfo"; // MUST match INITIAL STate key

/**
 * This reducer will return the state-slice for case search.
 * When the action.type is :
 * -  CASE_SEARCH_FAILURE the action will have a property 'caseSearchError'
 * -  CASE_SEARCH_SUCCESS the action will have a property 'caseSearchResult'
 * -  SAVE_APPLIED_FILTERS the action will have a property 'filters'
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function InteractiveReducer(
    state = InitialState[STATE_SLICE_NAME],
    action
) {
    switch (action.type) {

        case GET_DOWNLOAD_HISTORY:
            //assign data to store
            return {
                ...state,
                error: action.getDownloadHistory,
                downloads: action.downloadsResults
            };

        case FETCH_DOWNLOAD_DETAILS:
            //assign data to store
            return {
                ...state,
                error: action.getDownloadDetails,
                downloadDetails: action.downloadDetails
            };

        case FETCH_KP_DOWNLOAD_DETAILS:
            //assign data to store
            return {
                ...state,
                error: action.getKPDownloadDetails,
                kpDownloadDetails: action.kpDownloadDetails
            };

        //default: return existing state
        default:
            return state;
    }
}

export const selectors = {
    getDownloadHistory: appState => appState[STATE_SLICE_NAME].downloads,
    getDownloadDetails: appState => appState[STATE_SLICE_NAME].downloadDetails,
    getKPDownloadDetails: appState => appState[STATE_SLICE_NAME].kpDownloadDetails
};

import {
  isArray,
  map, isNaN, camelCase, isEqual, last, head, flatten, replace, isEmpty, forEach
} from "utils/commonFunc";
import moment from "moment";
import queryString from "query-string";
import CONFIG from "config/configProps";
import CONFIG_REPORT from "config/config-report";
export const formatDateString = (
  date,
  formatString = CONFIG.DATE_TIME_FORMAT.UI
) => moment(date).format(formatString);

/**
 * return the Dropdown format object
 * @param {*} value value
 * @param {*} label label
 */
export const dropdownFormat = (value, label) => ({ label, value });
export const treeFormat = (value, label, children, level) => ({ id: label, label: value, children: children, level });

export const dropdownFormatWithSort = (value, label, sortBy) => ({
  ...dropdownFormat(value, label),
  sortBy
});

/**
 * return [true] if input Value is null or empty, else return [false]
 * @param {*} value value nuder consideration
 */
export const isEmptyOrNull = value => {
  if (value === "" || value === null || value === undefined) return true;
  else if (isArray(value)) return value.length === 0;
  else if (typeof value === "number") return value < 0;
  else if (typeof value === "string") return value.length === 0;
  else if (isEmpty(value)) return true;
  else if (isEmptyDateRange(value)) return true;
  else return false;
};

export const isEmptyDateRange = dateRange => {
  return dateRange.from === "" || dateRange.to === "";
};

/**
 * Format Date Object
 * @param {*} param0
 */
export const formatDate = ({ from, to }, format = "DD MMM'YY") => {
  if (from && to) {
    return (
      formatDateString(from, format) + " - " + formatDateString(to, format)
    );
  } else return "";
};


/**
 * Format Date Object
 * @param {*} param0
 */
export const formatRange = ({ min, max }) => {
  if (min && max) {
    return (
      min + " - " + max
    );
  } else return "";
};

/**
 * helper method to convert the JSON object into a string which can be stored or assigned as URL
 * @param {*} jsonObject JSON Object
 * @param {*} fieldSeparator fieldSeprator used to seprate the Key Value Pairs
 */
export const getStringFromJson = (jsonObject, fieldSeparator = "&") => {
  let arrayOfNonEmptyFields = [];
  map(Object.keys(jsonObject), key => {
    if (!isEmptyOrNull(jsonObject[key])) {
      if (isArray(jsonObject[key])) {
        // value is an array
        arrayOfNonEmptyFields.push(key + "=" + jsonObject[key].join(","));
      } else if (typeof jsonObject[key] === "string") {
        arrayOfNonEmptyFields.push(key + "=" + jsonObject[key]);
      } else if (
        isEqual(key, CONFIG_REPORT.FILTERS.STAFF_COMPLETION.field)
      ) {
        arrayOfNonEmptyFields.push(
          map(Object.keys(jsonObject[key]), _key => {
            return (
              camelCase([_key, key].join(" ")) +
              "=" +
              jsonObject[key][_key]
            );
          })
        );
      } else if (Object.keys(jsonObject[key]).length === 2) {
        // value is an object. In this case: DateRange
        arrayOfNonEmptyFields.push(
          map(Object.keys(jsonObject[key]), _key => {
            return (
              camelCase([key, _key].join(" ")) +
              "=" +
              formatDateString(jsonObject[key][_key], "YYYY-MM-DD")
            );
          })
        );
      } else {
        arrayOfNonEmptyFields.push(key + "=" + jsonObject[key]);
      }
    } else if (
      (isEqual(key, CONFIG_REPORT.FILTERS.INCLUDE_DELETED_ATTACHMENTS.field) ||
        isEqual(key, CONFIG_REPORT.FILTERS.INCLUDE_WITHDRAWN_CASES.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.INCLUDE_INACTIVE_DOWNLOADER.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.KP_CMP_FLAG.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.CMP_FLAG.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.GTM_FLAG.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.INCLUDE_INACTIVE_STAFF.field)
        || isEqual(key, CONFIG_REPORT.FILTERS.UPDATED_LAST_12MONTHS.field)
      ) &&
      !isArray(jsonObject[key])
    ) {
      arrayOfNonEmptyFields.push(key + "=" + jsonObject[key]);
    }
  });
  return flatten(arrayOfNonEmptyFields).join(fieldSeparator);
};

/**
 * method to return the query parameters
 * @param {*} locationSearch location.search from router
 */
export const getQueryParams = locationSearch =>
  queryString.parse(locationSearch);

export const getQueryParamsString = locationSearchObject =>
  queryString.stringify(locationSearchObject);

/**
 * helper method to return the truncated string with specified max length
 * @param {*} stringValue string value
 * @param {*} maxLength max length, default is 10
 */
export const getTruncatedString = (stringValue, maxLength = 10) => {
  if (isEmpty(stringValue)) {
    return "";
  }
  const truncatedString = stringValue.substr(0, maxLength);
  return stringValue.length > maxLength ? truncatedString + "..." : truncatedString;
};

export const convertStringArrayToNumberArray = stringArray => {
  return map(stringArray, str => (isNaN(Number(str)) ? str : Number(str)));
};

export const getNestedObjectById = (
  arrayOfNestedObjects,
  identifier,
  identifierKey = "id"
) => {
  let resultantObject = {};
  forEach(arrayOfNestedObjects, obj => {
    if (obj[identifierKey] === identifier) {
      resultantObject = obj;
      return false;
    } else if (isArray(obj.children) && obj.children.length > 0) {
      resultantObject = getNestedObjectById(
        obj.children,
        identifier,
        identifierKey
      );
      if (!isEmpty(resultantObject)) return false;
    } else {
      return;
    }
  });
  return resultantObject;
};

export const handleInAppropriatePageIndexAndSize = filtersObject => {
  const filtersObjectCopy = { ...filtersObject };
  let isError = false;
  if (
    Number(filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_SIZE.field]) >
    last(CONFIG_REPORT.FILTERS.PAGE_SIZE.values).id
  ) {
    // if a user tries to enter in appropriate Page Sizes
    filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_SIZE.field] = last(
      CONFIG_REPORT.FILTERS.PAGE_SIZE.values
    ).id;
    isError = isError || true;
  }
  if (
    Number(filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_SIZE.field]) <
    head(CONFIG_REPORT.FILTERS.PAGE_SIZE.values).id
  ) {
    // if a user tries to enter in appropriate Page Sizes
    filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_SIZE.field] = head(
      CONFIG_REPORT.FILTERS.PAGE_SIZE.values
    ).id;
    isError = isError || true;
  }
  if (Number(filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_INDEX.field]) < 1) {
    // if a user tries to enter in appropriate Page Sizes
    filtersObjectCopy[CONFIG_REPORT.FILTERS.PAGE_INDEX.field] = 1;
    isError = isError || true;
  }

  return { data: filtersObjectCopy, error: isError };
};

export const convertToDropDownFormatITService = (label, value, desc) => ({
  key: value,
  text: label,
  value,
  desc
});

export const removeCharFromString = (
  stringValue,
  oldCar,
  newChar = "",
  onlyLastOccurence
) =>
  onlyLastOccurence
    ? stringValue.replace("/;(?!.*;)/gm", "")
    : replace(stringValue, new RegExp(oldCar, "g"), newChar);


/**
 * Check if data is exists or api call is in progress
 * @param {*} response
 */

export const isFetchRequired = response => {
  return isEmpty(response?.data) && !response?.isLoading && !response?.error;
};


/**
 * COhorts Segments To filter Adobe Data
 */
export const COHORT_VALUES = {
  CT_KT: "s200001082_5f71c1a8e3fdbf178e857acb",
  CT_OTHERS: "s200001082_5f71c1e41f49c67aebfd27fa",
  KT_OTHERS: "s200001082_5f71c220e0a67a5cf195edd5",
  KT: "s200001082_5d68fa9f5e39e410f01d9f98",
  CT: "s200001082_5ca70f4c9c6d590a5e5adcd4",
  OTHERS: "s200001082_5f60a19af6205777722d124c"
};

export const FILTER_SEGMENTS = [
  { value: "CT", label: "CT Cohort" },
  { value: "KT", label: "KT Cohort" },
  { value: "OTHERS", label: "Other Cohort" }
];

export const COHORT_SEGMENTS = [
  { value: "s200001082_62d698e370ff9d78b322d47a", label: "MDP" },
  { value: "s200001082_62d699dd41ac0b46d20d4b01", label: "Partners, PL & Principal" },
];


export function getDerivedDataForChart(data) {
  const result = { bar: [], line: [], average: 0 };
  let cumilative = 0;
  forEach((data && data.rows) || [], (item, index) => {
    cumilative += item.data ? item.data[0] : 0;
    result.bar.push([item.value, item.data && item.data[0]]);
    result.line.push([item.value, cumilative / (index + 1)]);
  });
  result.average = cumilative / result.line.length;
  result.total = cumilative;
  return result;
}

export function getDerivedDataForMultiRow(data) {
  const result = { bar: [], line: [], average: 0 };
  let cumilative = 0;
  forEach(data || [], (item, index) => {
    cumilative += item.count || 0;
    result.bar.push([item.row.value, item.count || 0]);
    result.line.push([item.row.value, cumilative / (index + 1)]);
  });
  result.average = cumilative / result.line.length;
  result.total = cumilative;
  return result;
}

export function isDataRestricted(data) {
  return data === CONFIG.RESTRICTED;
}

export function scrollToReport(reportViewClassName) {
  const reportViewElementArr = document.getElementsByClassName(reportViewClassName);
  let maxRetry = 10;
  const extraPadding = 60;
  if (reportViewElementArr?.length > 0) {
    window.scrollTo(0, ((reportViewElementArr[0]?.offsetTop || 0) + extraPadding));
  } else if (maxRetry > 0) {
    setTimeout(() => {
      scrollToReport(reportViewClassName);
      return;
    }, 500);
    maxRetry--;
  }
}

import InitialState from "./InitialState";
import { FETCH_ACTION_ITEMS_SUCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "actionItems";

export const ActionItemsReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_ACTION_ITEMS_SUCCESS: {
      const { data } = action;
      return data;
    }
    default:
      return state;
  }
};

export const selectors = {
  getActionItems: appState => appState[STATE_SLICE_NAME] || {}
};

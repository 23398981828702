import * as types from '../actions/actionTypes';
const initialState = {
	checkBrowser: false,
};


const checkBrowserReducer = function (state = initialState, action) {
	switch (action.type) {
		case types.CHECK_IE_BROWSER: {
			return Object.assign({}, state, {
				checkBrowser: action.data
			});
		}

		default:
			return state;
	}
};
export default checkBrowserReducer;

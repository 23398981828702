import CONFIG_REPORT from "config/config-report";
import { map } from 'utils/commonFunc';
import { sendToLocalStorage } from "../localStorage";

/**
 * method to read the config fiel for Field-Values for Filters and create a map
 * to enable faster searching of filternames from filter-field.
 *
 * Once map is generated, it will be stored in localStorage and never needs to be updated
 */
// eslint-disable-next-line func-names
(function () {
  const fieldMap = {};
  map(CONFIG_REPORT.FILTERS, valueObj => {
    fieldMap[valueObj.field] = {
      text: valueObj.text,
      options: valueObj.values,
      mapperField: valueObj.mapperField
    };
  });
  sendToLocalStorage(CONFIG_REPORT.FILTER_MAP, fieldMap);
})();

import InitialState from "./InitialState";
import { FETCH_IMP_LINKS_LIST_SUCCESS } from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "impLinks";

export const ImpLinksReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_IMP_LINKS_LIST_SUCCESS: {
      const { data } = action;
      return data;
    }
    default:
      return state;
  }
};

export const selectors = {
  getImpLinks: appState => appState[STATE_SLICE_NAME] || {}
};

import {
  ADOBE_ANALYTICS_TOKEN_SUCCESS,
  ADOBE_ANALYTICS_TOKEN_FAILURE,
  ADOBE_ANALYTICS_DATA_SUCCESS,
  ADOBE_ANALYTICS_DATA_FAILURE,
  ADOBE_APPLIED_FILTER,
  ADOBE_APPLIED_GTM_FILTER
} from "redux/actions/actionTypes";

import InitialState from "./InitialState";
const STATE_SLICE_NAME = "adobeAnalytics"; // MUST match INITIAL STate key

export function adobeAnalyticsReducer(
  state = InitialState[STATE_SLICE_NAME],
  action
) {
  switch (action.type) {
    case ADOBE_ANALYTICS_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token
      };
    case ADOBE_ANALYTICS_TOKEN_FAILURE:
      return {
        ...state,
        token: {},
        error: action.error
      };
    case ADOBE_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        analyticsData: {
          ...state.analyticsData,
          [action.dashboardIdentifier]: {
            data: action.result,
            error: undefined
          }
        }
      };
    case ADOBE_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        analyticsData: {
          ...state.analyticsData,
          [action.dashboardIdentifier]: {
            data: undefined,
            error: action.error
          }
        }
      };
    case ADOBE_APPLIED_FILTER:
      return {
        ...state,
        filterObject: action.filterObject
      };

    case ADOBE_APPLIED_GTM_FILTER:

      return {
        ...state,
        gtmFilters: { ...state.gtmFilters, ...action.filter }
      };
    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  getAdobeToken: appState => {
    const tokenObj = appState[STATE_SLICE_NAME].token;
    if (tokenObj) {
      return `Bearer ${tokenObj.access_token}`;
    }
    return undefined;
  },
  getAdobeData: (appState, identifier) => appState[STATE_SLICE_NAME].analyticsData[identifier]
};

import axios from "axios";

function clearIdTokens() {
  const cookies = document.cookie.split(';');
  localStorage.clear();
  sessionStorage.clear();
  for (const cookie of cookies) {
    const cookieParts = cookie.trim().split("=");
    document.cookie = `${cookieParts[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
  window.location.reload();
}

axios.interceptors.request.use(
  config => {
    const tokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN));
    if (tokens && tokens.accessToken && tokens.accessToken.accessToken) {
      const accessToken = tokens.accessToken.accessToken;
      let AuthStr = "Bearer " + accessToken;
      return {
        ...config,
        headers: {
          ...config.headers,
          Accept: "*",
          Authorization: AuthStr,
          "x-content-type-options": "nosniff",
          "x-frame-options": "deny",
          "x-xss-protection": "1; mode=block",
          "Cache-Control":
            "private, no-cache, no-store, max-age=0, must-revalidate",
          Pragma: "no-cache"
        }
      };
    } else {
      window.location.reload();
      return Promise.reject();
    }
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    switch (response.status) {

      case 401:
        clearIdTokens();
        break;
      case 404:
        return Promise.reject(response?.message);
      default:
        return response;
    }
  },
  error => {
    if (error.response) {

      switch (error.response.status) {
        case 401:
          clearIdTokens();
          break;
        case 404:
          return Promise.reject(error.response);
        default:
          return Promise.reject(error.response);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

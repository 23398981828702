export const STATE_KEY = {
    PROFILE_COMPLETION: {
        summary: 'resultsProfileCompletionSummary',
        practiceArea: 'resultsProfileCompletionPracticeArea',
        region: 'resultsProfileCompletionRegion',
        skills: 'resultsProfileCompletionSkills',
        expertise: 'resultsProfileCompletionExpertise',
        bioDownloads: 'resultsProfileCompletionBioDownloads',
        resumeDownloads: 'resultsProfileCompletionResumeDownloads',
        completionTrend: 'resultsProfileCompletionCompletionTrend',
        sectionCompletion: 'resultsProfileCompletionSectionCompletion',
        materialDownloads: 'resultsProfileCompletionMaterialDownloads',
        materialContributed: 'resultsProfileCompletionMaterialContributed',
        cases: 'resultsProfileCompletionCases',
        popularAuthor: 'resultsProfileCompletionPopularAuthor'
    }
};
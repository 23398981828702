import React, { Suspense, Component, lazy } from "react";
import { Route, Switch, withRouter, } from "react-router-dom";
import { Provider } from 'react-redux';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { toRelativeUrl } from "@okta/okta-auth-js";
import { createBrowserHistory } from "history";
import store from "redux/store";

import oktaAuth from "config/auth";
import Spinner from "components/common/Spinner/Spinner";
import { data } from "config/config-api";

const redirectUri = '/implicit/callback';

const browserHistory = createBrowserHistory();
const HomeComponent = lazy(() => import("./Main"));

class App extends Component {
    constructor(props) {
        super(props);
        this.loadAdobeScript();
        this.state = {};
    }

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    loadAdobeScript = () => {
        const analyticsUrl = data.adobe;
        if (analyticsUrl) {
            const script = document.createElement("script");
            script.src = analyticsUrl;
            script.async = true;
            document.head.appendChild(script);
        }
    };

    render() {
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri} >
                <Switch>
                    <Route
                        path={redirectUri}
                        component={LoginCallback}
                    />
                    <Suspense fallback={
                        <Spinner styleProp={{
                            margin: '0 auto', position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        />
                    }>
                        <Provider store={store}>
                            <SecureRoute path='' component={HomeComponent} history={browserHistory} />
                        </Provider>
                    </Suspense>
                </Switch>
            </Security>
        );
    }
}

export default withRouter(App);
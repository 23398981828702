import InitialState from "./InitialState";
import {
  FETCH_SERVICES_LIST_SUCCESS,
  FETCH_TIME_LABEL_LIST_SUCCESS
} from "redux/actions/actionTypes";
import { convertToDropDownFormatITService } from "utils";
import { map, values } from "utils/commonFunc";
const STATE_SLICE_NAME = "seedData";

export const SeedDataReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action
) => {
  switch (action.type) {
    case FETCH_SERVICES_LIST_SUCCESS:
      return { ...state, servicesList: action.data };
    case FETCH_TIME_LABEL_LIST_SUCCESS:
      return { ...state, timeLabelList: action.data };

    default:
      return state;
  }
};

export const selectors = {
  getServiceList: appState => {
    const stateValue = appState[STATE_SLICE_NAME]["servicesList"] || {};
    const dropdownValues = [
      convertToDropDownFormatITService("Select Service", "select"),
      ...map(values(stateValue), ({ serviceName, id }) =>
        convertToDropDownFormatITService(serviceName, id)
      )
    ];
    return dropdownValues;
  },
  getTimeLabelList: appState => {
    const stateValue = appState[STATE_SLICE_NAME]["timeLabelList"] || {};
    const dropdownValues = [
      convertToDropDownFormatITService("Select Quarter", "select"),
      ...map(values(stateValue), ({ intervalName, id }) =>
        convertToDropDownFormatITService(intervalName, id)
      )
    ];
    return dropdownValues;
  },
  getServiceDetails: appState => serviceIdentifier => {
    let result = {};
    if (appState[STATE_SLICE_NAME]["servicesList"]) {
      if (serviceIdentifier) {
        result = appState[STATE_SLICE_NAME]["servicesList"][serviceIdentifier];
      } else {
        result = values(appState[STATE_SLICE_NAME]["servicesList"]);
      }
    }
    return result;
  },
  getTimeLabelDetails: appState => timeLabelID => {
    let result = {};
    if (appState[STATE_SLICE_NAME]["timeLabelList"]) {
      if (timeLabelID) {
        result = appState[STATE_SLICE_NAME]["timeLabelList"][timeLabelID];
      } else {
        result = values(appState[STATE_SLICE_NAME]["timeLabelList"]);
      }
    }
    return result;
  },
};
